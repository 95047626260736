<template>
  <iframe
    v-if="userInfoReadyTag"
    :src="iframeSerc"
    frameborder="0"
    id="gameframe"
    name="gameframe"
    style="width: 100%; height: 100%"
    class="gametemplate"
  ></iframe>
  <loading v-else></loading>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import loading from "@/components/loading/loading.vue";
import common from "../utils/common";

let props = defineProps(["jsonUrl", "folder"]);

let store = useStore();
store.commit("set_showPageTop", false);
let userInfoReadyTag = computed(() => {
  return store.state.userInfoReadyTag;
});
let iframeSerc = ref("");

onMounted(() => {
  common.showLoading("get game config json");
  axios
    .get(props.jsonUrl + "?t=" + new Date().getTime())
    .then((r) => {
      console.log("--lb", r.data.path);
      if (r.data.path) {
        iframeSerc.value = r.data.path + "/index.html" + location.search;
        console.table([
          { name: "path", value: r.data.path },
          { name: "gameSrc", value: iframeSerc.value },
        ]);
      }
    })
    .catch((e) => {
      iframeSerc.value = props.folder + "/index.html" + location.search;
    })
    .finally(() => {
      common.hideLoading("get game config json");
    });
  console.log("--lb", "thie is game!", props.jsonUrl);
});
</script>
<style scoped>
iframe {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}
</style>
